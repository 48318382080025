<template>
  <div class="tw-container tw-mt-12 tw-text-right">
    <p>
      <span class="tw-font-bold">API Host:</span>
      {{ apiUrl }}
      <br />

      <span class="tw-font-bold">Commit:</span>
      {{ buildkiteCommit }}
    </p>
  </div>
</template>

<script lang="ts" setup>
const { $config } = useNuxtApp()

const apiUrl = $config.public.baseUrl
const buildkiteCommit = $config.public.buildkiteCommit
</script>
